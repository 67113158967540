import React from 'react';
import ReactDOM from 'react-dom';

import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import Callback from "./routes/Callback";

import './index.css';
import App from './App';

const rootElement = document.getElementById("root");
ReactDOM.render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<App />} />
            <Route path="callback" element={<Callback />} />
        </Routes>
    </BrowserRouter>,
    rootElement
);
