import React, { useEffect, useState } from "react";

import WeightRecord from "../model/Weight";
import Milestones from "./Milestones";

// Javascript is bad
const msInAWeek = 60 * 60 * 24 * 7 * 1000;
const twoWeeksAgo = Date.now() - (msInAWeek * 2);

export interface IWeightStatsProps {
    records: WeightRecord[]
}

export default function WeightStats(props: IWeightStatsProps) {
    const [sevenDayLossRate, setSevenDayLossRate] = useState<number | undefined>();
    const [twoWeeksLossRate, setTwoWeekLossRate] = useState<number | undefined>();
    const [startingWeight, setStartingWeight] = useState<WeightRecord>();
    const [currentWeight, setCurrentWeight] = useState<WeightRecord>();
    const [projectionStartWeight, setProjectionStartWeight] = useState<WeightRecord>();

    useEffect(() => {
        const sortedWeights = props.records.sort((a, b) => a.Timestamp.getTime() - b.Timestamp.getTime());
        const [start, end] = [sortedWeights[0], sortedWeights[sortedWeights.length - 1]];
        const twoWeeksAgoWeight = sortedWeights.filter(w => w.Timestamp.getTime() < twoWeeksAgo).pop();

        setCurrentWeight(end);
        setStartingWeight(start);
        setSevenDayLossRate(getPastWeekLossRate(props.records));
        setTwoWeekLossRate(getWeeklyLossRate(twoWeeksAgoWeight ?? start, end));
        setProjectionStartWeight(twoWeeksAgoWeight);
    }, [props.records]);

    const getPastWeekLossRate = (sortedRecords: WeightRecord[]) => {
        let oneWeekAgo = new Date(Date.now() - msInAWeek);

        let pastWeekWeights = sortedRecords
            .filter(r => r.Timestamp > oneWeekAgo);

        if (pastWeekWeights.length <= 1) {
            return undefined;
        }

        return pastWeekWeights[0].Value - pastWeekWeights[pastWeekWeights.length - 1].Value;
    };

    const getWeeklyLossRate = (start: WeightRecord, end: WeightRecord) => {
        let totalLoss = start.Value - end.Value;
        let totalWeeks = (end.Timestamp.getTime() - start.Timestamp.getTime()) / msInAWeek;
        
        return totalLoss / totalWeeks;
    };

    const getTotalLoss = () => {
        if (startingWeight === undefined || currentWeight === undefined) {
            return 0;
        }

        return startingWeight.Value - currentWeight.Value;
    }

    return <>
        <h3>Latest measurement ({currentWeight?.Timestamp.toLocaleString()}): {currentWeight?.Value.toFixed(2)} lbs</h3>
        <h3>Total loss (since {startingWeight?.Timestamp.toLocaleString()}): {getTotalLoss().toFixed(2)} lbs</h3>
        <h3>Loss over past week: {sevenDayLossRate?.toFixed(2)} lbs</h3>
        <h3>Loss over past 2 weeks: {twoWeeksLossRate?.toFixed(2)} lbs</h3>

        {startingWeight === undefined || currentWeight === undefined ?
            null :
            <Milestones
                StartingWeight={projectionStartWeight ?? startingWeight}
                CurrentWeight={currentWeight}
            />
        }
    </>;
}