import React, { useEffect } from 'react';
import {
    useNavigate,
    useSearchParams
} from 'react-router-dom';

export default function Callback() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        const authCode = searchParams.get('code');
        const state = searchParams.get('state');
        const tokenTS = Date.now();

        fetch(`/api/WithingsAccessToken?authCode=${authCode}&state=${state}`).then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }

            response.json().then(tokenResponse => {
                // store the full access token response, along with a timestamp of when we made the
                // request so we know when we need to request a new token 
                localStorage.setItem("withingsToken", JSON.stringify(tokenResponse.body));
                localStorage.setItem("withingsTokenTS", tokenTS.toString());
                
                navigate("../", {
                    replace: true
                });
            })
        }).catch(err => {
            console.log(err);
        });
    });
    
    return (
        <div style={{ padding: "1rem 0" }}>
            <h2>Withings OAuth 2.0 Callback...</h2>
        </div>
    );
}
