import React, {
    useState,
    useEffect
} from 'react';

import Grid from '@mui/material/Unstable_Grid2';

import ThirtyDayWeightGraph from './components/ThirtyDayWeightGraph';
import WeightStats from './components/WeightStats';
import WeightRecord from './model/Weight';

export default function App() {
    const [weightRecords, setWeightRecords] = useState<WeightRecord[]>([]);

    // TODO Build authentication into a component
    const [loggedIn, setLoggedIn] = useState(false);
    const authUrl = new URL('https://account.withings.com/oauth2_user/authorize2');
    authUrl.search = new URLSearchParams({
        response_type: 'code',
        client_id: process.env.REACT_APP_WITHINGS_CLIENT_ID ?? '',
        state: 'dummy-todo', // TODO
        scope: 'user.metrics',
        // TODO Is there a better way to do this?
        redirect_uri: `${window.location.protocol}//${window.location.host}/callback`
    }).toString();

    const logout = () => {
        localStorage.removeItem("withingsToken");
        localStorage.removeItem("withingsTokenTS");
        setLoggedIn(false);
        window.location.reload();
    };

    const refreshAccessToken = async (refreshToken: string) => {
        const tokenTS = Date.now();
        const response = await fetch(`/api/WithingsRefreshToken?refreshToken=${refreshToken}`);
    
        if (!response.ok) {
            throw Error(response.statusText);
        }

        const tokenResponse = await response.json();

        // store the full access token response, along with a timestamp of when we made the
        // request so we know when we need to request a new token 
        localStorage.setItem("withingsToken", JSON.stringify(tokenResponse.body));
        localStorage.setItem("withingsTokenTS", tokenTS.toString());
    };

    useEffect(() => {
        async function fetchRecords() {
            // TODO Break authentication out into its own thing (component? custom hook? idk)
            let withingsTokenStr = localStorage.getItem('withingsToken');
            const withingsTokenTSStr = localStorage.getItem('withingsTokenTS');

            if (!withingsTokenStr || !withingsTokenTSStr) {
                setLoggedIn(false);
                return;
            }

            let withingsToken = JSON.parse(withingsTokenStr);
            const withingsTokenTS = new Date(parseInt(withingsTokenTSStr)).getTime();
            const tokenExpirationDate = withingsTokenTS + (withingsToken.expires_in * 1000);

            // is our access token expired?
            if (Date.now() > tokenExpirationDate) {
                await refreshAccessToken(withingsToken.refresh_token);
                withingsTokenStr = localStorage.getItem('withingsToken');

                if (!withingsTokenStr) {
                    setLoggedIn(false);
                    return;
                }

                withingsToken = JSON.parse(withingsTokenStr);
            }

            // TODO Make this configurable
            let startDate = parseInt((new Date('2023/01/01').getTime() / 1000).toFixed(0));
            const response = await fetch(`https://wbsapi.withings.net/measure?action=getmeas&meastype=1&startdate=${startDate}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${withingsToken.access_token}`
                }
            });

            if (!response.ok) {
                console.log(`Failed to fetch weight records: ${response.status} ${response.statusText}`);
                setLoggedIn(false);
                return;
            }

            const json = await response.json();

            if (json.status !== 0) {
                setLoggedIn(false);
                return;
            }

            setLoggedIn(true);

            var records = WeightRecord.ParseRecords(json.body);
            setWeightRecords(records.sort((a, b) => (a.Timestamp.getTime() - b.Timestamp.getTime())));
        }

        fetchRecords().catch(console.error);
    }, []);

    if (!loggedIn) {
        return <a href={authUrl.toString()}>Login with Withings</a>;
    }

    if (weightRecords.length <= 0) {
        return <p>No weight records to display.</p>;
    }

    return <>
        <Grid container spacing={2}>
            <Grid sm={12} md={6}>
                <WeightStats records={weightRecords} />
            </Grid>
            <Grid sm={12} md={6}>
                <ThirtyDayWeightGraph weightData={weightRecords} />
            </Grid>
        </Grid>
        <button onClick={logout}>Logout</button>
    </>;
}
