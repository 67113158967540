import React, { useEffect, useState } from "react";
import WeightRecord from "../model/Weight";

// TODO Make milestones configurable in the UI
const milestones = [200, 185, 154];

interface IMilestoneEstimate {
    Weight: number,
    Timestamp: Date,
    Achieved: boolean
}

export interface IMilestonesProps {
    StartingWeight: WeightRecord,
    CurrentWeight: WeightRecord
}

export default function Milestones(props: IMilestonesProps) {
    const [estimates, setEstimates] = useState<IMilestoneEstimate[]>([]);

    useEffect(() => {
        // TODO Do something useful if the current weight is higher than the starting weight
        const totalLoss = props.StartingWeight.Value - props.CurrentWeight.Value;
        const totalTime = props.StartingWeight.Timestamp.getTime() - props.CurrentWeight.Timestamp.getTime();
        const lossRate = totalLoss / totalTime;

        let updatedEstimates: IMilestoneEstimate[] = milestones.map(milestone => {
            const remaining = props.CurrentWeight.Value - milestone;

            if (remaining < 0) {
                return {
                    Weight: milestone,
                    Timestamp: new Date(),
                    Achieved: true
                };
            }

            const projectedDate = new Date(Date.now() + (remaining / -lossRate));

            return {
                Weight: milestone,
                Timestamp: projectedDate,
                Achieved: false
            };
        });

        setEstimates(updatedEstimates);
    }, [props.StartingWeight, props.CurrentWeight]);

    const renderEstimate = (estimate: IMilestoneEstimate) => {
        if (estimate.Achieved)
        {
            return <h3 key={ estimate.Weight }>Achieved goal of { estimate.Weight } lbs!</h3>;
        }

        return <h3 key={ estimate.Weight }>Goal of {estimate.Weight} lbs projected for { estimate.Timestamp.toLocaleDateString() }</h3>;
    }

    return <>
        {
            estimates.map(e => renderEstimate(e))
        }
    </>;
}
