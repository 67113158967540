import React from "react";
import WeightRecord, { toWeightedRollingAverage } from "../model/Weight";

import {
    Chart as ChartJS,
    ChartData,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';

export interface IThirtyDayWeightGraphProps {
    weightData: WeightRecord[]
}

ChartJS.register(
    CategoryScale,
    TimeScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const oneDay = 24 * 60 * 60 * 1000;

export default function ThirtyDayWeightGraph(props: IThirtyDayWeightGraphProps) {
    const thirtyDays = oneDay * 30;
    const thirtyDaysAgo = new Date(Date.now() - thirtyDays);
    const thirtyDayWeights = props.weightData.filter(w => w.Timestamp > thirtyDaysAgo);
    const rollingAvgWeights = toWeightedRollingAverage(thirtyDayWeights, 7);

    const data: ChartData<"line"> = {
        datasets: [{
            label: `Weight (lbs)`,
            data: thirtyDayWeights.map(w => ({
                x: w.Timestamp.getTime(),
                y: w.Value
            })),
            fill: false,
            borderColor: 'rgb(75, 192, 192, 0.3)',
            tension: 0.4,
            yAxisID: 'yAxis',
            xAxisID: 'xAxis'
        }, {
            label: `Rolling Average (7 days)`,
            data: rollingAvgWeights.map(w => ({
                x: w.Timestamp.getTime(),
                y: w.Value
            })),
            fill: false,
            borderColor: 'rgb(255, 64, 105)',
            tension: 0.4,
            pointStyle: 'line',
            yAxisID: 'yAxis',
            xAxisID: 'xAxis'
        }]
    };

    return (
        <Line
            data={data}
            options={{
                scales: {
                    xAxis: {
                        type: 'time',
                        display: true,
                        title: {
                            display: true,
                            text: 'Date'
                        },
                        ticks: {
                            autoSkip: true,
                            maxRotation: 0
                        },
                        min: thirtyDaysAgo.getTime(),
                        max: Date.now()
                    },
                    yAxis: {
                        display: true,
                        title: {
                            display: true,
                            text: 'Weight'
                        }
                    }
                }
            }}
        />
    );
}
