
const gramsToLbs = 0.00220462;
const oneDay = (24 * 60 * 60 * 1000);

export function toRollingAverage(weightRecords: WeightRecord[], daysToAvg: number): WeightRecord[] {
    const halfWindow = (oneDay * daysToAvg) / 2;

    return weightRecords.map((record, index) => {
        const windowStartDate = record.Timestamp.getTime() - halfWindow;
        const windowEndDate = record.Timestamp.getTime() + halfWindow;
        
        const windowWeights: WeightRecord[] = weightRecords
            .filter(w =>
                w.Timestamp.getTime() >= windowStartDate &&
                w.Timestamp.getTime() <= windowEndDate);
        const windowTotal = windowWeights
            .map(w => w.Value)
            .reduce((a, b) => a + b);
        const average = windowTotal / windowWeights.length;

        return {
            Timestamp: record.Timestamp,
            Value: average
        };
    });
};

export function toWeightedRollingAverage(weightRecords: WeightRecord[], daysToAvg: number): WeightRecord[] {
    const halfWindow = (oneDay * daysToAvg) / 2;

    return weightRecords.map((centerRecord, index) => {
        const centerRecordTime = centerRecord.Timestamp.getTime();
        const windowStartDate = centerRecordTime - halfWindow;
        const windowEndDate = centerRecordTime + halfWindow;
        
        const weightedWindowRecords: [record: WeightRecord, mass: number][] = weightRecords
            .filter(nearbyRecord =>
                nearbyRecord.Timestamp.getTime() > windowStartDate &&
                nearbyRecord.Timestamp.getTime() < windowEndDate)
            .map(nearbyRecord => {
                const distance = Math.abs(centerRecordTime - nearbyRecord.Timestamp.getTime());
                const mass = (halfWindow - distance) / halfWindow;

                return [nearbyRecord, mass];
            });
        const windowTotalMass = weightedWindowRecords
            .map(([_, mass]) => mass)
            .reduce((a, b) => a + b);
        const windowTotalWeight = weightedWindowRecords
            .map(([record, mass]) => record.Value * mass)
            .reduce((a, b) => a + b);;
        const average = windowTotalWeight / windowTotalMass;

        return {
            Timestamp: centerRecord.Timestamp,
            Value: average
        };
    });
};

export default class WeightRecord {
    Value: number;
    Timestamp: Date;
    // TODO Add a unit here once we support multiple weight units
    // Assume lbs for now

    constructor(value: number, timestamp: Date) {
        this.Value = value;
        this.Timestamp = timestamp;
    }

    // https://developer.withings.com/api-reference#operation/measure-getmeas
    // https://developer.withings.com/api-reference#section/Models/Measures
    static ParseRecords(rawWeightRecords: any) {
        let records: WeightRecord[] = [];

        rawWeightRecords.measuregrps.forEach((group: any) => {
            let unixDate: number = group.date;

            group.measures.forEach((measure: any) => {
                records.push({
                    Value: measure.value * gramsToLbs,
                    Timestamp: new Date(unixDate * 1000)
                });
            });
        });

        return records;
    }
}
